import React, { useState, useEffect } from 'react';
import * as tf from '@tensorflow/tfjs';
import './MakeDatasets.css';

function GetCanvas(){
  return new Promise((resolve) => {
    const video = document.getElementById('video');
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    console.log(canvas.width);
    console.log(canvas.height);
    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    const result = canvas.toDataURL();
    resolve(result);
  })
}


export function MakeDatasets(){
  const [showCamera, setShowCamera] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);
  const [existCanvas, setExistCanvas] = useState(false);
  const [label, setLabel] = useState();
  const [type, setType] = useState(0);
  const [image, setImage] = useState();
  const [video, setVideo] = useState();
  const numberOfShots = 5;
  const style = {
    maxWidth: '22vh',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto'
  };


  const startCamera = () => {
    setShowCamera(true);
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" }})
      .then(function(stream){
        const video = document.getElementById('video');
        setVideo(video);
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.log(err.name + ": " + err.message);
      })
  };

  const takeScreenshot = async () => {
    setExistCanvas(true);
    setImage(await GetCanvas());
    console.log("takeScreenshot");
    console.log("type:" + type);
  };

  const takeLabel = async(label) => {
    setLabel(label);
    setExistCanvas(false);
    console.log("type:" + type)
    await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/azure/addData?type=${type}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        image: image,
        label: label
      })
    })
  };

  const changeType = (tmp) => {
    const frame = document.getElementById("frame");
    console.log(frame);
    if(frame){
      if(tmp === 0){
        frame.style.width = '80%';
        frame.style.aspectRatio = '1.618 / 1';
        setType(0);
      }else if(tmp === 1){
        frame.style.width = '70%';
        frame.style.aspectRatio = '1 / 1.414';
        setType(1);
      }
    }
    console.log("type:" + type);
  }

  return (
    <div class="RetakeDemo">
      <div class="container">
        {!showCamera && !showPhoto && (
          <div>
            <button class="btn-start" onClick={startCamera}>カメラ起動</button>
          </div>
        )}
        {showCamera && (
          <div class="webcam-container" style={{height:'150vh'}}>
            <button onClick={() => changeType(0)} class="btn-start">card</button>
            <button onClick={() => changeType(1)} class="btn-start">A4</button>
            <div style={style}>
              <div id="frame" style={{aspectRatio: "1.618 / 1"}}></div>
              <video id="video" style={{objectFit:'cover', width:'100%', height:'100%'}} class="webcam-video" autoplay playsinline="playsinline"></video>
            </div>
            <button onClick={takeScreenshot} class="btn-start">撮影</button>
            <div style={style}>
              <canvas id="canvas" style={{ objectFit:'cover', width: '100%', height: '100%' }}></canvas>
            </div>
            {existCanvas && (
              <div>
                <button onClick={() => takeLabel("OK")} class="btn-start">OK</button>
                <button onClick={() => takeLabel("close")} class="btn-start">close</button>
                <button onClick={() => takeLabel("far")} class="btn-start">far</button><br></br>
                <button onClick={() => takeLabel("None")} class="btn-start">None</button>
              </div>
            )}
            {!existCanvas && (
              <h2>label: {label}</h2>
            )}
          </div>
        )}         
      </div>
    </div>
  );
}
