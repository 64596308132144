import React from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

function DemoPage(){
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    navigate("/RegisterDemo");
  }
  return (
    <Container className="mt-5">
      <Button variant="primary" type="submit" onClick={handleClick}>会員登録</Button>
    </Container>
  );
}

export default DemoPage;
