import React, { useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Button, Spinner, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

async function GetReport(userID){
  console.log(userID);
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/report?userID=${userID}`);
  const data = await response.json();
  return data;
}

async function GetSelfie(mediaID, userID){
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/media?mediaID=${mediaID}&userID=${userID}`);
  const data = await response.text();
  return data;
}

async function GetDocument(href, userID){
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/document?href=${href}&userID=${userID}`);
  const data = await response.text();
  return data;
}

async function DeleteUser(userID){
  const formData = new FormData();
  formData.append('userID', userID);
  await fetch('https://20.222.3.236:8443/alice/user', {
    method: 'DELETE',
    body: formData
  });
}

async function AuthorizeUser(userID){
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/authorize?userID=${userID}`);
  const data = await response.text();
  console.log(data);
}

function Loader({ src, children }) {
  if (!src) {
    return (
      <Spinner
        animation="border"
        variant="primary"
        style={{
          display: "block",
          margin: "auto",
        }}
      />
    );
  }
  const content = typeof children === "function" ? children(src) : children;
  return content;
}

function ReportPage(){
  const location = useLocation();
  const userID = location.state?.userID;
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/List");
  }
  const handleDleteClick = async () => {
    await DeleteUser(userID);
    const temp = JSON.parse(localStorage.getItem("userData"));
    console.log(temp);
    localStorage.setItem("userData", JSON.stringify(temp.filter(item=> item.user.user_id !== userID)));
    console.log(JSON.parse(localStorage.getItem("userData")));
    navigate("/List");
  }
  const handleAuthClick = async () => {
    await AuthorizeUser(userID);
    navigate("/List");
  }
  const [report, setReport] = useState();
  const [selfie, setSelfie] = useState();
  const [driverLicenseBack, setDriverLicenseBack] = useState();
  const [driverLicenseFront, setDriverLicenseFront] = useState();
  const [selfieCreatedAt, setSelfieCreatedAt] = useState();
  const [selfieId, setSelfieId] = useState();
  const [liveness, setLiveness] = useState();
  const [issuingCountry, setIssuingCountry] = useState();
  const [documentCreatedAt, setDocumentCreatedAt] = useState();
  const [documentId, setDocumentId] = useState();
  useEffect(() => {
    const fetchReport = async () => {
      try{
        const data = await GetReport(userID);
        console.log(data);
        setReport(data);
      }catch(error){
        console.log(error.message);
      }
    };
    fetchReport();
  }, [userID]);
  useEffect(() => {
    const fetchSelfie = async () => {
      try{
        const img = await GetSelfie(report.report.selfies[0].id, userID);
        setSelfie(img);
      }catch(error){
        console.log(error.message);
      }
    }
    if(report && !(report.report.selfies.length === 0)){
      fetchSelfie();
    }
  }, [report, userID]);
  useEffect(() => {
    const fetchDriversLicense = async () => {
      try{
        const back  = await GetDocument(report.report.documents[0].sides.back.media.document.href, userID);
        const front = await GetDocument(report.report.documents[0].sides.front.media.document.href, userID);
        setDriverLicenseBack(back);
        setDriverLicenseFront(front);
      }catch(error){
        console.log(error.message);
      }
    }
    if(report){
      fetchDriversLicense();
    }
  }, [report, userID]);
  useEffect(() => {
    if(report && !(report.report.selfies.length === 0)){
      const selfieCreatedAt = report.report.selfies[0].created_at;
      const selfieId = report.report.selfies[0].id;
      const liveness = report.report.selfies[0].liveness;
      setSelfieCreatedAt(selfieCreatedAt);
      setSelfieId(selfieId);
      setLiveness(liveness);
    }
    if(report && !(report.report.documents.length === 0)){
      const issuingCountry = report.report.documents[0].meta.issuing_country;
      const documentCreatedAt = report.report.documents[0].created_at;
      const documentId = report.report.documents[0].id;
      setIssuingCountry(issuingCountry);
      setDocumentCreatedAt(documentCreatedAt);
      setDocumentId(documentId);
    }
  }, [report]);
  console.log(selfie);
  return (
    <Container>
      <Button onClick={handleBackClick} size="lg">戻る</Button>{'  '}
      <Button onClick={handleDleteClick} size="lg">削除</Button>{'  '}
      <Button onClick={handleAuthClick} size="lg">認証</Button><br/><br/>
      <h2>結果</h2>
      <div className="row">
        <div className="col-md-6" style={{width: '40%'}}>
          <div
            className="border border-primary rounded p-3 d-flex flex-column"
            style={{
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              width: '100%',
              aspectRatio: '1 / 1'
            }}
          >
            <h4>顔認証画像</h4>
            <Loader src={selfie}>
              <Image
                src={`data:image/png;base64,${selfie}`}
                alt="selfie"
                className="img-fluid"
                style={{ width: '100%', height: '100%', objectFit: 'contain'}}
              />
              <div style={{textAlign: 'left'}} className="text-primary font-weight-bold">Selfie data</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{width: "70px", textAlign: 'left'}}>Created at</span>
                <span>{selfieCreatedAt}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{width: "70px", textAlign: 'left'}}>Selfie UUID</span>
                <span style={{ display: 'inline-block', wordWrap: 'break-word', maxWidth: '100%' }}>{selfieId}</span>
              </div>
              <div style={{textAlign: 'left'}} className="text-primary font-weight-bold">Selfie checks</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{width: "70px", textAlign: 'left'}}>Liveness</span>
                <span>{Math.floor(liveness)}/100</span>
              </div>
            </Loader>
          </div>
          <br></br>
          <div className="border border-primary rounded">
            <h4>レポート結果</h4>
            <Loader src={report}>
              <pre style={{ textAlign: 'left'}}>{JSON.stringify(report, null, 2)}</pre>
            </Loader>
          </div>
        </div>
        <div className="col-md-6" style={{width: '60%'}}>
          <div className="border border-primary rounded p-3 d-flex flex-column">
            <h4>運転免許証</h4>
            <div className="row">
              <div className="col-md-6">
                <h4>表</h4>
                <Loader src={driverLicenseFront}>
                  <Image
                    src={`data:image/png;base64,${driverLicenseFront}`}
                    alt="selfie"
                    className="img-fluid"
                    style={{ width: '100%', height: '90%', objectFit: 'contain'}}
                    fluid
                  />
                </Loader>
              </div>
              <div className="col-md-6">
                <h4>裏</h4>
                  <Loader src={driverLicenseBack}>
                    <Image
                      src={`data:image/png;base64,${driverLicenseBack}`}
                      alt="selfie"
                      className="img-fluid"
                      style={{ width: '100%', height: '90%', objectFit: 'contain'}}
                      fluid
                    />
                  </Loader>
              </div>
            </div>
            <Loader src={report}>
              <div style={{textAlign: 'left'}} className="text-primary font-weight-bold">Document data</div>
              <div style={{ display: 'flex', alignItems: 'left' }}>
                <span style={{width: "150px", textAlign: 'left'}}>Issuing country</span>
                <span>{issuingCountry}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'left' }}>
                <span style={{width: "150px", textAlign: 'left'}}>Created at</span>
                <span>{documentCreatedAt}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'left' }}>
                <span style={{width: "150px", textAlign: 'left'}}>Document UUID</span>
                <span style={{ display: 'inline-block', textAlign: 'left', wordWrap: 'break-word', maxWidth: '100%' }}>{documentId}</span>
              </div>
              <div style={{textAlign: 'left'}} className="text-primary font-weight-bold">Document result</div>
            </Loader>
          </div>
        </div>
      </div>
    </Container>
  );
}
export default ReportPage;
