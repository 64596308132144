import React from 'react';
import "aliceonboarding/dist/aliceonboarding.css";
import { useLocation, useNavigate } from 'react-router-dom';
const aliceonboarding = require("aliceonboarding")

export class MyAuthenticator extends aliceonboarding.Authenticator {
  constructor(email, lastName, firstName) {
    super();
    this.email = email;
    this.lastName = lastName;
    this.firstName = firstName;
  }
  execute() {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('getemail', this.email);
      formData.append('getlastName', this.lastName);
      formData.append('getfirstName', this.firstName);
      const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/user_token', {
        method: 'POST',
        body: formData,
      });
      const token = await response.text();
      resolve(token);
    });
  }
}


export function Alice(){
  const location = useLocation();
  const email = location.state?.email;
  const firstName = location.state?.firstName;
  const lastName = location.state?.lastName;
  console.log('email: ' + email);
  console.log('firstName: ' + firstName);
  console.log('lastName: ' + lastName);
  
  let authenticator = new MyAuthenticator(email, lastName, firstName);
  authenticator.execute()
    .then((userToken) => {
      let documentStageConfig = new aliceonboarding.DocumentStageConfig(
          aliceonboarding.DocumentCapturerType.CAMERA,
          true);
      let config = new aliceonboarding.OnboardingConfig()
        .withUserToken(userToken)
        .withAddSelfieStage()
//        .withAddDocumentStage(aliceonboarding.DocumentType.IDCARD)
        .withAddDocumentStage(aliceonboarding.DocumentType.DRIVERLICENSE, "JPN", documentStageConfig)
        .withCustomLocalization("ja");
      const onboarding = new aliceonboarding.Onboarding("alice-onboarding-mount", config);
      function onSuccess(userInfo) {
        window.location.href = `/AliceResult?userToken=${userToken}`;
        console.log("onSuccess");
        console.log(userInfo);
      }
      function onFailure(error) {
        window.location.href = "/";
        console.log("onFailure")
        console.log(error);
      }
      function onCancel(cancel) {
        window.location.href = "/";
        console.log("onCancel");
        console.log(cancel);
      }
      onboarding.run(onSuccess, onFailure, onCancel);
    })
  return (
    <div id="alice-onboarding-mount"></div>
  );
}

export function Auth(){
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const userToken = queryParams.get("userToken");
  const email = queryParams.get("email");
  console.log(userToken);
  let config = new aliceonboarding.OnboardingConfig()
    .withUserToken(userToken)
    .withCustomLocalization("ja")
  let onboardingCommands = new aliceonboarding.OnboardingCommands(
    "alice-onboarding-mount",
    userToken,
    config
  );
  function onSuccess(userInfo) {
    navigate('/AuthResult', { state: { authID: userInfo.authentication_id, email: email }});
    console.log("userInfo");
    console.log(userInfo);
  }
  function onFailure(error) {
//    navigate("/");
    console.log("onFailure")
    console.log(error);
  }
  function onCancel(cancel) {
//    navigate("/");
    console.log("onCancel");
    console.log(cancel);
  }
  onboardingCommands.authenticate(onSuccess, onCancel, onFailure, aliceonboarding.CameraType.FACE);
//      onboardingCommands.getUserStatus(onSuccess, onFailure);
  return (
    <div id="alice-onboarding-mount"></div>
  );
}
