import React, { lazy } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import StartPage from './components/StartPage'
import { Alice, Auth } from './components/Alice'
import { AliceResult, AuthResult, AzureResult } from './components/Result'
import Azure from './components/Azure'
import ListPage from './components/ListPage'
import LoginPage from './components/LoginPage'
import ReportPage from './components/ReportPage'
import DemoPage from './components/DemoPage'
import RegisterDemo from './components/RegisterDemo'
import AliceDemo from './components/AliceDemo'
import AliceResultDemo from './components/AliceResultDemo'
import { HokenDemo, LoginHokenDemo, AliceHokenDemo, AzureHokenDemo, EndHokenDemo } from './components/HokenDemo'
import { BankDemo, AcceptBankDemo, LoginBankDemo, AliceBankDemo, AliceResultBankDemo, AzureBankDemo, EndBankDemo } from './components/BankDemo'
import { AppclipDemo, AcceptAppclipDemo, LoginAppclipDemo, AliceAppclipDemo, AliceResultAppclipDemo, AzureAppclipDemo, EndAppclipDemo } from './components/AppclipDemo'
import { PhotoDemo } from './components/PhotoDemo'
import { RetakeDemo } from './components/RetakeDemo'
import { MakeDatasets } from './components/MakeDatasets'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<StartPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/Alice' element={<Alice />} />
          <Route path='/AliceResult' element={<AliceResult />} />
          <Route path='/AuthResult' element={<AuthResult />} />
          <Route path='/AzureResult' element={<AzureResult />} />
          <Route path='/Azure' element={<Azure />} />
          <Route path='/Auth' element={<Auth />} />
          <Route path='/List' element={<ListPage />} />
          <Route path='/Report' element={<ReportPage />} />
          <Route path='/Demo' element={<DemoPage />} />
          <Route path='/RegisterDemo' element={<RegisterDemo />} />
          <Route path='/AliceDemo' element={<AliceDemo />} />
          <Route path='/AliceResultDemo' element={<AliceResultDemo />} />
          <Route path='/PhotoDemo' element={<PhotoDemo />} />
          <Route path='/RetakeDemo' element={<RetakeDemo />} />
          <Route path='/MakeDatasets' element={<MakeDatasets />} />
          <Route path='/HokenDemo' element={<HokenDemo />} >
            <Route path='/HokenDemo/Login' element={<LoginHokenDemo />} />
            <Route path='/HokenDemo/Alice' element={<AliceHokenDemo />} />
            <Route path='/HokenDemo/Azure' element={<AzureHokenDemo />} />
            <Route path='/HokenDemo/End'   element={<EndHokenDemo />} />
          </Route>
          <Route path='/BankDemo' element={<BankDemo />} >
            <Route path='/BankDemo/Accept' element={<AcceptBankDemo />} />
            <Route path='/BankDemo/Login' element={<LoginBankDemo />} />
            <Route path='/BankDemo/Alice' element={<AliceBankDemo />} />
            <Route path='/BankDemo/AliceResult' element={<AliceResultBankDemo />} />
            <Route path='/BankDemo/End'   element={<EndBankDemo />} />
          </Route>
          <Route path='/AppclipDemo' element={<AppclipDemo />} >
            <Route path='/AppclipDemo/Accept' element={<AcceptAppclipDemo />} />
            <Route path='/AppclipDemo/Login' element={<LoginAppclipDemo />} />
            <Route path='/AppclipDemo/Alice' element={<AliceAppclipDemo />} />
            <Route path='/AppclipDemo/AliceResult' element={<AliceResultAppclipDemo />} />
            <Route path='/AppclipDemo/End'   element={<EndAppclipDemo />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
