import React from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

function StartPage(){
  const navigate = useNavigate();

  const handleMainSubmit = (e) => {
    e.preventDefault();
    navigate("/login");
  }
  const handleListSubmit = (e) => {
    e.preventDefault();
    navigate("/List");
  }
  return (
    <Container className="mt-5">
      <Form onSubmit={handleMainSubmit}>
        <Button variant="primary" type="submit" size="lg">本人確認実施</Button>
      </Form><br></br>
      <Form onSubmit={handleListSubmit}>
        <Button variant="primary" type="submit" size="lg">一覧表示</Button>
      </Form>
    </Container>
  );
}

export default StartPage;
