import React, { useState, useEffect, useRef} from 'react';
import Webcam from 'react-webcam';
const { AzureKeyCredential, DocumentAnalysisClient } = require("@azure/ai-form-recognizer");

const key = "c8fdcb0a77064daaa8ea8fdf022780ef";
const endpoint = "https://inov-marke-test.cognitiveservices.azure.com/";

function GetOCR(image){
  console.log(image);
  const url = `https://${endpoint}.cognitiveservices.azure.com/formrecognizer/v3.0-preview/prebuilt/receipt`;
  const headers = {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': key
  };
  const requestBody = JSON.stringify({ 'source': image });
  fetch(url, {
    method: 'POST',
    headers: headers,
    body: requestBody
  })
  .then(response => response.json())
  .then(data => {
    console.log(data);
  })
  .catch(error => { console.error(error) })
}

function Azure(){
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);
  const [photoSrc, setPhotoSrc] = useState(null);
  const webcamRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setDevices(videoDevices);
        setSelectedDevice(videoDevices[0]?.deviceId);
      })
      .catch(err => {
        console.log(err.name + ": " + err.message);
      });
  }, []);

  const selectDevice = (deviceId) => {
    setSelectedDevice(deviceId);
    setShowCamera(true);
  };

  const takeScreenshot = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhotoSrc(imageSrc);
    setShowPhoto(true);
    setShowCamera(false);
    GetOCR(imageSrc);
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '20px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '5px'
    },
    list: {
      listStyleType: 'none',
      padding: 0,
      margin: 0
    },
    listItem: {
      padding: '10px',
      margin: '10px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      cursor: 'pointer'
    },
    photo: {
      maxWidth: '100%',
      maxHeight: '500px',
      margin: '20px'
    },
    button: {
      margin: '20px'
    }
  };

  return (
    <div style={styles.container}>
      {!showCamera && !showPhoto && (
        <ul style={styles.list}>
          {devices.map(device => (
            <li key={device.deviceId} style={styles.listItem} onClick={() => selectDevice(device.deviceId)}>
              {device.label}
            </li>
          ))}
        </ul>
      )}
      {showCamera && (
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ deviceId: selectedDevice }}
          />
          <button onClick={takeScreenshot} style={styles.button}>Take a picture</button>
        </div>
      )}
      {showPhoto && (
        <div>
          <img src={photoSrc} alt="Photo" style={styles.photo} />
          <button onClick={() => setShowPhoto(false)} style={styles.button}>Retake picture</button>
        </div>
      )}
    </div>
  );
}


export default Azure;
