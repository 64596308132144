import React, { useState, useEffect, useRef } from 'react';
import { useRoutes, useNavigate, useLocation } from 'react-router-dom';
import Webcam from 'react-webcam';
import { isMobile } from 'react-device-detect';
import { Buffer } from 'buffer';
import './BankDemo.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { MyAuthenticator } from './Alice.js';

export function AcceptBankDemo(props){
  const { handleSetStep } = props;
  handleSetStep(0);
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(false);
  const handleCheck = (e) => {
    setAccepted(e.target.checked);
  }
  const handleSubmit = (e) => {
    if(accepted){
      e.preventDefault();
      navigate("/BankDemo/Login");
    }else{
      alert('チェックボックスにチェックをして規定に同意してください');
    }
  }
  return (
    <div class="container">
      <form onSubmit={handleSubmit}>
        <h1>口座開設規定</h1>
        <p>
          以下の規定をよくお読みになり、同意する場合は下のボックスをチェックしてください。
        </p>
        <div style={{margin: '1em 0', height: '200px', overflow: 'auto', border: '1px solid #ccc'}}>
          <p>
            ここに規定内容が入ります。長い文章やリストも可能です。
          </p>
        </div>
        <div>
          <input type="checkbox" id="accept" checked={accepted} onChange={handleCheck} />
          <label htmlFor="accept">上記の規定に同意します</label>
        </div>
        <button type="submit">次へ</button>
        <iframe src="https://ino-marke.japaneast.cloudapp.azure.com:444" style={{width: '100%', height: '100%'}}></iframe>
      </form>
    </div>
  );
}

export function LoginBankDemo(props){
  const { handleSetStep } = props;
  handleSetStep(1);
  const navigate = useNavigate();
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState();
  const [number, setNumber] = useState();
  const handleClick = (e) => {
    e.preventDefault();
    navigate("/BankDemo/Alice", { state: {email: email, firstName: firstName, lastName: lastName}});
  };
  return (
    <div class="registration-form">
      <form>
        <div class="form-icon">
          <span><i class="bi bi-file-earmark"></i></span>
        </div>
        <div>
          <span><center><h3>口座開設</h3></center></span>
          <p>プリマジェスト証券の口座開設を行います、必要な情報を入力し本人確認をしてください。</p>
        </div>
        <div class="form-group">
          <input type="text" class="form-control item" id ="userlastname" placeholder="姓" onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div class="form-group">
          <input typ="text" class="form-control item" id="userfirstname" placeholder="名" onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div class="form-group">
          <input type="text" class="form-control item" id="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div class="form-group">
          <input type="text" class="form-control item" id="phone-number" placeholder="電話番号" onChange={(e) => setPhone(e.target.value)} />
        </div>
        <div class="form-group">
          <button type="button" class="btn btn-block create-account" onClick={handleClick}>認証する</button>
        </div>
      </form>
    </div>
  );
}

export function AliceBankDemo(props){
  const { handleSetStep } = props;
  handleSetStep(2);
  const location = useLocation();
  const email = location.state?.email;
  const firstName = location.state?.firstName;
  const lastName = location.state?.lastName;
  const aliceonboarding = require("aliceonboarding");
  const [flag, setFlag] = useState(false);

  if(!flag){
    let authenticator = new MyAuthenticator(email, lastName, firstName);
    authenticator.execute()
    .then((userToken) => {
      let documentStageConfig = new aliceonboarding.DocumentStageConfig(
        aliceonboarding.DocumentCapturerType.CAMERA,
        true);
      let config = new aliceonboarding.OnboardingConfig()
        .withUserToken(userToken)
        .withAddSelfieStage()
        .withAddDocumentStage(aliceonboarding.DocumentType.PASSPORT, "JPN", documentStageConfig)
        .withAddDocumentStage(aliceonboarding.DocumentType.IDCARD, "JPN", documentStageConfig)
        .withCustomLocalization("ja");
      const onboarding = new aliceonboarding.Onboarding("alice-onboarding-mount", config);
      function onSuccess(userInfo){
        console.log("success");
        window.location.href = `/BankDemo/AliceResult?email=${email}`;
      }
      function onFailure(error){
        console.log(error);
      }
      function onCancel(cancel){
        console.log(cancel);
      }
      onboarding.run(onSuccess, onFailure, onCancel);
    })
    setFlag(true);
  }
  return (
    <div class="alice-container">
      <br></br>
      <div id="alice-onboarding-mount" />
    </div>
  );
}

export function AliceResultBankDemo(props){
  const { handleSetStep } = props;
  handleSetStep(2);
  const location = useLocation();
  const email = location.state?.email;
  const handleApp = (e) => {
    e.preventDefault();
    window.location.href = `myapp://email?address=${email}`;
  };
  return (
    <div class="container">
      <h2>本人確認完了</h2>
      <div>アプリで書類を読み込んでください</div>
      <button type="button" class="btn-start" onClick={handleApp}>アプリを起動</button>
    </div>
  );
}

async function GetUserID(email){
  const formData = new FormData();
  formData.append('email', email);
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/userid?email=${email}`, {
    method: 'POST',
    body: formData
  });
  const data = await response.text();
  return data;
}

async function GetReport(userID){
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/report?userID=${userID}`);
  const data = await response.json();
  return data;
}

async function GetSelfie(report, userID){
  const mediaID = report.report.selfies[0].id;
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/media?mediaID=${mediaID}&userID=${userID}`);
  const data = await response.text();
  return data;
}

async function GetDocument(href, userID){
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/document?href=${href}&userID=${userID}`);
  const data = await response.text();
  return data;
}

async function GetNfc(email){
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/getnfcdata?email=${email}`);
  const json = await response.json();
  return json;
}

function GetAge(birthday){
  const today = new Date();
  const birthdate = new Date(birthday);
  const currentYearBirthday = new Date(today.getFullYear(), birthdate.getMonth(), birthdate.getDate());
  // 生まれた年と今年の差を計算
  let age = today.getFullYear() - birthdate.getFullYear();
  
  // 今日の日付と今年の誕生日を比較
  if (today < currentYearBirthday) {
  // 今年誕生日を迎えていない場合、1を引く
    age--;
  }
  console.log(age);
  // 年齢の値を返す
  return age;
}

export function EndBankDemo(props){
  const { handleSetStep } = props;
  handleSetStep(3);
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const email = params.get("email");
  const [userID, setUserID] = useState();
  const [report, setReport] = useState();
  const [selfie, setSelfie] = useState();
  const [nfc, setNfc] = useState();
  const [flag, setFlag] = useState(true);
  if(selfie && flag){
    console.log(report);
    console.log(flag);
    setFlag(false);
  }
  useEffect(() => {
    const fetchUserID = async () => {
      try{
        setUserID(await GetUserID(email));
      }catch(error){
        console.error(error);
      }
    }
    fetchUserID();
  })
  useEffect(() => {
    const fetchNfc = async () => {
      try{
        setNfc(await GetNfc(email));
      }catch(error){
        console.error(error);
      }
    }
    fetchNfc();
  }, [email])
  useEffect(() => {
    const fetchReport = async () => {
      try{
        setReport(await GetReport(userID));
      }catch(error){
        console.error(error);
      }
    }
    if(userID){
      fetchReport();
    }
  }, [userID])
  useEffect(() => {
    const fetchImage = async () => {
      try{
        setSelfie(await GetSelfie(report, userID));
      }catch(error){
        console.error(error);
      }
    }
    if(userID && report && !(report.report.selfies.length === 0)){
      fetchImage(report, userID);
    }
  }, [report])

  const handleClick = () => {
    console.log("button click");
    handleSetStep(-1);
    navigate("/BankDemo");
  }

  return(
    <div class="container">
      <h1>完了</h1><br></br>
      <button onClick={handleClick} class="btn-start">戻る</button><br></br>
      { report && (
        <div>
          { selfie && (
            <div>
              <img src={"data:image/jpeg;base64," + selfie} style={{width: "80%"}} />
            </div>
          )}
          <div style={{ textAlign: "left" }}>
            <span>face_liveness:</span>
            <span>{report.report.summary.face_liveness}</span><br></br>
            <span>Age {GetAge(report.report.summary.user_data[2].value)}</span><br></br>
            { nfc && (
              <div>
                <span>NFC情報</span><br></br>
                <span>{nfc.birth_date}</span><br></br>
                <span>{nfc.address}</span><br></br>
                <span>{nfc.drivers_number}</span><br></br>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function ProgressBar({step}){
  const stepclass = Array(4).fill("item");
  if(0 < step || step < 4){
    stepclass[step]="item active";
  }
  return (
    <div class="Bankprogressbar">
      <div class={stepclass[0]}>STEP.1<br></br>ご同意</div>
      <div class={stepclass[1]}>STEP.2<br></br>ご入力</div>
      <div class={stepclass[2]}>STEP.3<br></br>本人確認</div>
      <div class={stepclass[3]}>STEP.4<br></br>書類提出</div>
    </div>
  );
}

export function BankDemo(props){
  const navigate = useNavigate();
  const [step, setStep] = useState(-1);
  const handleClick = () => {
    navigate('/BankDemo/Accept');
  };
  const handleSetStep = (step) => {
    setStep(step);
  };
  const element = useRoutes([
    { path: '', element: <button class="btn-start" onClick={handleClick}>口座開設</button> },
    { path: 'Accept', element: <AcceptBankDemo handleSetStep={handleSetStep} /> },
    { path: 'Login', element: <LoginBankDemo handleSetStep={handleSetStep} /> },
    { path: 'Alice', element: <AliceBankDemo handleSetStep={handleSetStep} /> },
    { path: 'AliceResult', element: <AliceResultBankDemo handleSetStep={handleSetStep} /> },
    { path: 'End'  , element: <EndBankDemo   handleSetStep={handleSetStep} /> },
  ]);

  return (
    <div class="BankDemo">
      <ProgressBar step={step}></ProgressBar>
      { element }
    </div>
  );
}

