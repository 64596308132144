import React, { useState, useEffect, useRef } from 'react';
import { useRoutes, useNavigate, useLocation } from 'react-router-dom';
import Webcam from 'react-webcam';
import './HokenDemo.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { MyAuthenticator } from './Alice.js';
import { isMobile } from 'react-device-detect';

export function LoginHokenDemo(props){
  const { handleSetStep } = props;
  handleSetStep(0);
  const navigate = useNavigate();
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState();
  const [number, setNumber] = useState();
  const handleAliceClick = (e) => {
    e.preventDefault();
    navigate("/HokenDemo/Alice", { state: {email: email, firstName: firstName, lastName: lastName}});
  };
  return (
    <div class="registration-form">
      <form>
        <div class="form-icon">
          <span><i class="bi bi-file-earmark"></i></span>
        </div>
        <div>
          <span><center><h3>保険料請求</h3></center></span>
          <p>保険の請求を行います、必要な情報を入力し医療明細書を提出してください。</p>
        </div>
        <div class="form-group">
          <input type="text" class="form-control item" id ="userlastname" placeholder="姓" onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div class="form-group">
          <input typ="text" class="form-control item" id="userfirstname" placeholder="名" onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div class="form-group">
          <input type="text" class="form-control item" id="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div class="form-group">
          <input type="text" class="form-control item" id="phone-number" placeholder="電話番号" onChange={(e) => setPhone(e.target.value)} />
        </div>
        <div class="form-group">
          <input type="text" class="form-control item" id="birth-date" placeholder="保険証券番号" onChange={(e) => setNumber(e.target.value)} />
        </div>
        <div class="form-group">
          <button type="button" class="btn btn-block create-account" onClick={handleAliceClick}>本人認証する</button>
        </div>
      </form>
    </div>
  );
}

export function AliceHokenDemo(props){
  const { handleSetStep } = props;
  handleSetStep(1);
  const location = useLocation();
  const email = location.state?.email;
  const firstName = location.state?.firstName;
  const lastName = location.state?.lastName;
  const [flag, setFlag] = useState(false);
  if(!flag){
    console.log("email:" + email);
    console.log("firstName:" + firstName);
    console.log("lastName:" + lastName);
    const aliceonboarding = require("aliceonboarding");
    let authenticator = new MyAuthenticator(email, lastName, firstName);
    authenticator.execute()
    .then((userToken) => {
      let documentStageConfig = new aliceonboarding.DocumentStageConfig(
        aliceonboarding.DocumentCapturerType.CAMERA,
        true);
      let config = new aliceonboarding.OnboardingConfig()
        .withUserToken(userToken)
        .withAddSelfieStage()
        .withAddDocumentStage(aliceonboarding.DocumentType.DRIVERLICENSE, "JPN", documentStageConfig)
        .withAddDocumentStage(aliceonboarding.DocumentType.IDCARD, "JPN", documentStageConfig)
        .withCustomLocalization("ja");
      const onboarding = new aliceonboarding.Onboarding("alice-onboarding-mount", config);
      function onSuccess(userInfo){
        console.log("success");
        window.location.href = `/HokenDemo/Azure?email=${email}`;
      }
      function onFailure(error){
        console.log(error);
      }
      function onCancel(cancel){
        console.log(cancel);
      }
      onboarding.run(onSuccess, onFailure, onCancel);
    })
    setFlag(true);
  }
  return (
    <div class="alice-container">
      <br></br>
      <div id="alice-onboarding-mount" />
    </div>
  );
}

async function GetUserID(email){
  const formData = new FormData();
  formData.append('email', email);
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/userid`, {
    method: 'POST',
    body: formData
  });
  const data = await response.text();
  return data;
}

async function UploadBlob(image, email, blobName){
  const { BlobServiceClient } = require("@azure/storage-blob");
  const atob = require("atob");
  const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/azure/storage/sastoken');
  const sasToken = await response.text();
  const blobAccountName = 'inomarkeblob';
  const containerName = await GetUserID(email);
    
  const imageBinary = atob(image);
  var uint8Array = new Uint8Array(imageBinary.length);
  for(var i = 0; i < imageBinary.length; i++){
    uint8Array[i] = imageBinary.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: 'image/png' });

  const blobServiceUri = `https://${blobAccountName}.blob.core.windows.net?${sasToken}`;
  const blobServiceClient = new BlobServiceClient(blobServiceUri, null);
  const containerClient = await blobServiceClient.getContainerClient(containerName);
  try{
    await containerClient.create();
  }catch(error){
    console.log("container exists");
  }
  const blockBlobClient = await containerClient.getBlockBlobClient(blobName);
  await blockBlobClient.upload(blob, blob.size);
  return blockBlobClient.url;
}

async function GetOCR(blobUrl){
  const formData = new FormData();
  formData.append('blobUrl', blobUrl);
  const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/azure/formrecognizer/ocr', {
    method: 'POST',
    body: formData
  });
  const data = await response.text();
  return data;
}

async function SelectImage(images){
  const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/azure/selectimage', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
    },
    body: JSON.stringify({ images }) 
  });
  const data = await response.text();
  return data;
}

async function GetPoint(image){
  const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/azure/getlaplacian', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
    },
    body: JSON.stringify({ image }) 
  });
  const data = await response.text();
  return parseFloat(data.trim(), 10);
}

function GetCanvas(){
  return new Promise((resolve) => {
    const video = document.getElementById('video');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const aspectRatio = 4 / 3;
    let width, height, x, y;
    if(video.videoWidth / video.videoHeight > aspectRatio){
      width = video.videoHeight * aspectRatio;
      height = video.videoHeight;
      x = (video.videoWidth - width) / 2;
      y = 0;
    }else{
      width = video.videoWidth;
      height = width / aspectRatio;
      x = 0;
      y = (video.videoHeight - height) / 2;
    }
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(video, x, y, width, height, 0, 0, width, height);
    resolve(canvas.toDataURL());
  })
}

export function AzureHokenDemo(props){
  const { handleSetStep } = props;
  handleSetStep(2);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const email = params.get("email");
  const [devices, setDevices] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const [showFrame, setShowFrame] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);
  const [sendPhoto, setSendPhoto] = useState(false);
  const [photoSrc, setPhotoSrc] = useState(null);
  const [images, setImages] = useState([]);
  const [points, setPoints] = useState([]);
  const [flag, setFlag] = useState(false);
  const numberOfShots = 5;
  const style = {
    maxWidth: '100%',
    aspectRatio: '4 / 3',
    overflow: 'hidden',
  };
  const videoStyle = {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  };

  useEffect(async() => {
    if(isMobile){
      navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" }})
        .then(function(stream){
          const video = document.getElementById('video');
          video.srcObject = stream;
          video.play();
        })
        .catch(err => {
          console.log(err.name + ": " + err.message);
        })
      setShowCamera(true);
    }else{
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const videoDevices = devices.filter(device => device.kind.includes('video'));
          setDevices(videoDevices);
      })
      .catch(err => {
        console.log(err.name + ": " + err.message);
      })
    }
  }, []);

  if(images.length === numberOfShots && points.length === numberOfShots && !flag){
    console.log(images);
    console.log(points);
    console.log(Math.max(...points));
    setPhotoSrc(images[points.indexOf(Math.max(...points))]);
    setFlag(true);
  }

  const handleWebcamLoaded = () => {
    setShowFrame(true);
  };

  const selectDevice = (deviceId) => {
    setShowCamera(true);
    navigator.mediaDevices.getUserMedia({ video: { deviceId: deviceId }})
      .then(function(stream){
        const video = document.getElementById('video');
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.log(err.name + ": " + err.message);
      })
  };

  const getPictures = async () => {
    const tmpimages = [];
    const tmppoints = [];
    const promises = [];
    for (let i = 0; i < numberOfShots; i++) {
      promises.push(
        new Promise((resolve) => {
          setTimeout(async () => {
            const screenshot = await GetCanvas();
            tmpimages.push(screenshot);
            console.log(`[${i}] Image captured: ${Date.now()}ms`); // 開始からの経過時間をログ出力
            setImages(tmpimages);
            const tmppoint = await GetPoint(screenshot);
            tmppoints.push(tmppoint);
            setPoints(tmppoints);
            resolve();
          }, i * 100);
        })
      );
    }
    await Promise.all(promises);
  }; 

  const takeScreenshot = async () => {
    setShowPhoto(true);
    await getPictures();
    setShowCamera(false);
  };

  const retakeScreenshot = () => {
    setShowPhoto(false);
    setShowFrame(false);
    if(isMobile){
      setShowCamera(true);
    }
  }

  const endTakePhoto = async() => {
    setSendPhoto(true);
    const image = photoSrc.split(",")[1];
    const blobUrl = await UploadBlob(image, email, "meisai.png");
    const data = await GetOCR(blobUrl);
    window.location.href = `/HokenDemo/End?email=${email}`;
  };

  return (
    <div class="container">
      <h1>診療明細をスキャンしてください</h1>
      {!showCamera && !showPhoto && (
        <div>
          {!isMobile && (
            <ul class="devices">
              <h2>カメラを選択してください</h2>
              {devices.map(device => (
                <li key={device.deviceId} class="device" onClick={() => selectDevice(device.deviceId)}>
                  {device.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      {showCamera && (
        <div>
          <div class="webcam-container">
            {showFrame && (
              <div class="frame-overlay"></div>
            )}
            <div style={style}>
              <video id="video" style={videoStyle} class="webcam-video" autoplay playsinline="playsinline"></video>
            </div>
          </div>
          <button onClick={takeScreenshot} class="btn-start">撮影</button>
        </div>
      )}
      {showPhoto && (
        <div style={{maxWidth: '100%'}}>
          <div>selectedImage</div><br></br>
          <div>評価値:{Math.max(...points)}</div><br></br>
          <img src={photoSrc} alt="Photo" class="photo" /><br></br>
          {!sendPhoto && (
            <div>
              <button onClick={retakeScreenshot} class="btn-start">撮り直し</button>
              <button onClick={endTakePhoto} class="btn-start">送信</button>
            </div>
          )}
          {sendPhoto && (
            <h3>送信中</h3>
          )}
          {images.map((image, index) => (
            <div>
              <br></br><br></br>
              <div>評価値:{points[index]}</div><br></br>
              <img key={index} src={image} alt="Photo" class="photo" />
            </div>
          ))}
        </div>
      )}         
    </div>
  );
}

async function GetReport(userID){
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/report?userID=${userID}`);
  const data = await response.json();
  return data;
}

async function GetSelfie(report, userID){
  const mediaID = report.report.selfies[0].id;
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/media?mediaID=${mediaID}&userID=${userID}`);
  const data = await response.text();
  return data;
}

async function GetDocument(href, userID){
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/document?href=${href}&userID=${userID}`);
  const data = await response.text();
  return data;
}

function GetAge(birthday){
  const today = new Date();
  const birthdate = new Date(birthday);
  const currentYearBirthday = new Date(today.getFullYear(), birthdate.getMonth(), birthdate.getDate());
  // 生まれた年と今年の差を計算
  let age = today.getFullYear() - birthdate.getFullYear();
  
  // 今日の日付と今年の誕生日を比較
  if (today < currentYearBirthday) {
  // 今年誕生日を迎えていない場合、1を引く
    age--;
  }
  console.log(age);
  // 年齢の値を返す
  return age;
}

export function EndHokenDemo(props){
  const { handleSetStep } = props;
  handleSetStep(2);
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const email = params.get("email");
  const [userID, setUserID] = useState();
  const [report, setReport] = useState();
  const [selfie, setSelfie] = useState();
  const [dlFront, setDlFront] = useState();
  const [dlBack, setDlBack] = useState();
  const [flag, setFlag] = useState(true);
  if(selfie && dlFront && dlBack && flag){
    console.log(report);
    console.log(flag);
    setFlag(false);
    UploadBlob(selfie, email, "selfie.png");
    UploadBlob(dlFront, email, "DriversLicenseFront.png");
    UploadBlob(dlBack, email, "DriversLicenseBack.png");
  }
  useEffect(() => {
    const fetchUserID = async () => {
      try{
        setUserID(await GetUserID(email));
      }catch(error){
        console.error(error);
      }
    }
    fetchUserID();
  })
  useEffect(() => {
    const fetchReport = async () => {
      try{
        setReport(await GetReport(userID));
      }catch(error){
        console.error(error);
      }
    }
    if(userID){
      fetchReport();
    }
  }, [userID])
  useEffect(() => {
    const fetchImage = async () => {
      try{
        setSelfie(await GetSelfie(report, userID));
        setDlFront(await GetDocument(report.report.documents[0].sides.front.media.document.href, userID));
        setDlBack(await GetDocument(report.report.documents[0].sides.back.media.document.href, userID));
      }catch(error){
        console.error(error);
      }
    }
    if(userID && report && !(report.report.selfies.length === 0)){
      fetchImage(report, userID);
    }
  }, [report])
  const handleClick = () => {
    console.log("button click");
    handleSetStep(-1);
    navigate("/HokenDemo");
  }
  return(
    <div class="container" style={{ overflow: "auto", textAlign: "left" }}>
      <h1>完了</h1><br></br>
      <button onClick={handleClick} class="btn-start">戻る</button><br></br>
      <hr width="100%"></hr>
      <h3>以下管理画面のため非表示</h3>
      { report && (
        <div style={{ textAlign: "left" }}>
          <span>face_liveness:</span>
          <span>{report.report.summary.face_liveness}</span><br></br>
          <span>face_matching:</span>
          <span>{report.report.summary.face_matching[0].score}</span><br></br>
          <span>生年月日:</span>
          <span>{report.report.summary.user_data[2].value}</span>
          <span>: Age {GetAge(report.report.summary.user_data[2].value)}</span>
        </div>
      )}
    </div>
  );
}

function ProgressBar({step}){
  const stepclass = Array(3).fill("item");
  if(0 < step || step < 3){
    stepclass[step]="item active";
  }
  return (
    <div class="Hokenprogressbar">
      <div class={stepclass[0]}>STEP.1<br></br>ご入力</div>
      <div class={stepclass[1]}>STEP.2<br></br>本人確認</div>
      <div class={stepclass[2]}>STEP.3<br></br>書類提出</div>
    </div>
  );
}

export function HokenDemo(props){
  const navigate = useNavigate();
  const [step, setStep] = useState(-1);
  const handleClick = () => {
    navigate('/HokenDemo/Login');
  };
  const handleSetStep = (step) => {
    setStep(step);
  };
  const element = useRoutes([
    { path: '', element: <button class="btn-start" onClick={handleClick}>保険料請求開始</button> },
    { path: 'Login', element: <LoginHokenDemo handleSetStep={handleSetStep} /> },
    { path: 'Alice', element: <AliceHokenDemo handleSetStep={handleSetStep} /> },
    { path: 'Azure', element: <AzureHokenDemo handleSetStep={handleSetStep} /> },
    { path: 'End'  , element: <EndHokenDemo   handleSetStep={handleSetStep} /> },
  ]);

  return (
    <div class="HokenDemo">
      <ProgressBar step={step}></ProgressBar>
      { element }
    </div>
  );
}

