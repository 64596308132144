import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

function LoginPage(){
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const navigate = useNavigate();

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    navigate("/AliceDemo", { state: {email: email, firstName: firstName, lastName: lastName}});
  }
  return (
    <Container className="mt-5">
      <Form>
        <Form.Group>
          <Form.Label>メールアドレス：</Form.Label>
          <Form.Control
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>名前：</Form.Label>
          <Form.Control
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>苗字：</Form.Label>
          <Form.Control
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Group>
        <br/>
        <Button variant="primary" type="submit" onClick={handleRegisterSubmit}>次へ</Button><br/>
      </Form>
    </Container>
  );
}

export default LoginPage;
