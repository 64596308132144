import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import './PhotoDemo.css';
import { MyAuthenticator } from './Alice.js';
import { isMobile } from 'react-device-detect';

async function SelectImage(images){
  const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/azure/selectimage', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
    },
    body: JSON.stringify({ images }) 
  });
  const data = await response.text();
  return data;
}

async function GetPoint(image){
  const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/azure/getlaplacian', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
    },
    body: JSON.stringify({ image }) 
  });
  const data = await response.text();
  return parseFloat(data.trim(), 10);
}

function GetTrim(imageSrc){
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const aspectRatio = 4 / 3;
      let width, height, x, y;
      if(img.width / img.height > aspectRatio){
        width = img.height * aspectRatio;
        height = img.height;
        x = (img.width - width) / 2;
        y = 0;
      }else{
        width = img.width;
        height = width / aspectRatio;
        x = 0;
        y = (img.height - height) / 2;
      }
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, x, y, width, height, 0, 0, width, height);
      resolve(canvas.toDataURL());
    }
  })
}

export function PhotoDemo(){
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [showFrame, setShowFrame] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);
  const [sendPhoto, setSendPhoto] = useState(false);
  const [photoSrc, setPhotoSrc] = useState(null);
  const [images, setImages] = useState([]);
  const [points, setPoints] = useState([]);
  const [flag, setFlag] = useState(false);
  const webcamRef = useRef(null);
  const numberOfShots = 5;
  const videoConstraints = {
    video: true,
    audio: false,
    deviceId: selectedDevice,
    facingMode: "environment",
    width: 1280,
    height: 720
  };
  const videoStyle = {
    width: '90%',
    aspectRatio: '4 / 3',
    overflow: 'hidden',
  };
  const innerVideoStyle = {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    objectFit: 'cover',
    objectPosition: '50% 50%'
  };
  useEffect(async() => {
    if(isMobile){
      navigator.mediaDevices.getUserMedia()
        .then(device => {
          setSelectedDevice(device);
        })
        .catch(err => {
          console.log(err.name + ": " + err.message);
        })
      setShowCamera(true);
    }else{
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const videoDevices = devices.filter(device => device.kind.includes('video'));
          setDevices(videoDevices);
          setSelectedDevice(videoDevices[0]?.deviceId);
      })
      .catch(err => {
        console.log(err.name + ": " + err.message);
      })
    }
  }, []);

  if(images.length === numberOfShots && points.length === numberOfShots && !flag){
    console.log(images);
    console.log(points);
    console.log(Math.max(...points));
    setPhotoSrc(images[points.indexOf(Math.max(...points))]);
    setFlag(true);
  }

  const handleWebcamLoaded = () => {
    setShowFrame(true);
  };

  const selectDevice = (deviceId) => {
    setSelectedDevice(deviceId);
    setShowCamera(true);
  };

  const getPicture = async () => {
    const tmpimages = [];
    const tmppoints = [];
    const promises = [];
    for (let i = 0; i < numberOfShots; i++) {
      promises.push(
        new Promise((resolve) => {
          setTimeout(async () => {
            if (webcamRef.current) {
              const imageSrc = webcamRef.current.getScreenshot();
              const croppedScreenshot = await GetTrim(imageSrc);
              tmpimages.push(croppedScreenshot);
              console.log(`[${i}] Image captured: ${Date.now()}ms`); // 開始からの経過時間をログ出力
              setImages(tmpimages);
              const tmppoint = await GetPoint(imageSrc);
              tmppoints.push(tmppoint);
              setPoints(tmppoints);
            }
            resolve();
          }, i * 100);
        })
      );
    }
    await Promise.all(promises);
  }; 

  const takeScreenshot = async () => {
    setShowPhoto(true);
    await getPicture();
    setShowCamera(false);
  };

  const retakeScreenshot = () => {
    setShowPhoto(false);
    setShowFrame(false);
    if(isMobile){
      setShowCamera(true);
    }
  }

  const endTakePhoto = async() => {
    setSendPhoto(true);
  };

  return (
    <div class="PhotoDemo">
      <div class="container">
        <h1>診療明細をスキャンしてください</h1>
        {!showCamera && !showPhoto && (
          <div>
            {!isMobile && (
              <ul class="devices">
                <h2>カメラを選択してください</h2>
                {devices.map(device => (
                  <li key={device.deviceId} class="device" onClick={() => selectDevice(device.deviceId)}>
                    {device.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {showCamera && (
          <div>
            <div class="webcam-container">
              {showFrame && (
                <div class="frame-overlay"></div>
              )}
              <div style={videoStyle}>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  videoConstraints={videoConstraints}
                  onLoadedData={handleWebcamLoaded}
                  class="webcam-video"
                  style={innerVideoStyle}
                />
              </div>
            </div>
            <button onClick={takeScreenshot} class="btn-start">撮影</button>
          </div>
        )}
        {showPhoto && (
          <div>
            <div>selectedImage</div><br></br>
            <div>{Math.max(...points)}</div><br></br>
            <img src={photoSrc} alt="Photo" class="photo" /><br></br>
            {!sendPhoto && (
              <div>
                <button onClick={retakeScreenshot} class="btn-start">撮り直し</button>
                <button onClick={endTakePhoto} class="btn-start">送信</button>
              </div>
            )}
            {sendPhoto && (
              <h3>送信中</h3>
            )}
            {images.map((image, index) => (
              <div>
                <div>{points[index]}</div><br></br>
                <img key={index} src={image} alt="Photo" />
              </div>
            ))}
          </div>
        )}         
      </div>
    </div>
  );
}
