import React, { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Button, ListGroup, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

async function GetUsers(){
  const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/users');
  const data = await response.text();
  const users = JSON.parse(data);
  return users;
}

async function GetUserData(userIDs){
  const userData = [];
  for (let i = 0; i < userIDs.length; i++){
    const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/user/${userIDs[i]}`);
    const data = await response.json();
    userData.push(data);
  }
  return userData;
}

function ListPage(){
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/");
  }
  const handleListClick = (userID) => {
    navigate("/Report", { state: { userID: userID } });
  }
  const [users, setUsers] = useState([]);
  const [hovered, setHovered] = useState(null);
  useEffect(() => {
    const fetchUsers = async () => {
      console.log(users);
      if(users.length === 0){
        console.log("localStorage.get");
        console.log(users);
        setUsers(JSON.parse(localStorage.getItem("userData")));
      }
      const userIDs = await GetUsers();
      userIDs.reverse();
      const userData = await GetUserData(userIDs);
      console.log("localStorage.set");
      console.log(userData);
      localStorage.setItem("userData", JSON.stringify(userData));
      console.log(JSON.parse(localStorage.getItem("userData")));
      setUsers(userData);
    }
    fetchUsers();
  }, [users]);
  if(!users || users.length === 0){
    return (
      <Container>
        <Button onClick={handleBackClick} size="lg">戻る</Button>
        <br></br>
        <h2>結果一覧</h2>
        <Spinner animation="border" role="status"></Spinner>
    </Container>
    );
  }else{
    return (
      <Container>
        <h1>認証完了</h1>
        <Button onClick={handleBackClick} size="lg">戻る</Button>
        <h2>結果一覧</h2>
        <ListGroup>
          {users.map(user => (
            <ListGroup.Item
              key={user.user.user_id}
              onClick={() => handleListClick(user.user.user_id)}
              style ={{
                cursor: "pointer",
                padding: "10px",
                flexDirection: "column",
                backgroundColor: hovered === user.user.user_id ? '#ddd' : 'white',
              }}
              onMouseOver={() => setHovered(user.user.user_id)}
              onMouseLeave={() => setHovered(null)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{width: "70px", textAlign: 'left'}}>USER_ID</span> :  {user.user.user_id}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{width: "70px", textAlign: 'left'}}>MAIL</span> :  {user.user.email}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{width: "70px", textAlign: 'left'}}>NAME</span> :  {user.user.first_name} {user.user.last_name}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Container>
    );
  }
}

export default ListPage;
