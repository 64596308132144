import React, { useState, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { QRCodeSVG } from 'qrcode.react';
import { Container, Spinner } from 'react-bootstrap';

function Loader({ src, children }) {
  if (!src) {
    return (
      <Spinner
        animation="border"
        variant="primary"
        style={{
          display: "block",
          margin: "auto",
        }}
      />
    );
  }
  const content = typeof children === "function" ? children(src) : children;
  return content;
}

async function AuthorizeUser(email){
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/authorizedemo?email=${email}`);
  const data = await response.text();
  return data;
}

function AliceResultDemo(){
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userToken = queryParams.get("userToken");
  const email = queryParams.get("email");
  const [auth, setAuth] = useState();
  useEffect(() => {
    const fetchAuth = async () => {
      try{
        const data = await AuthorizeUser(email);
        console.log(data);
        setAuth(data);
      }catch(error){
        console.log(error.message);
      }
    };
    fetchAuth();
  }, [email]);
  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Loader src={auth}>
          <h1>会員登録完了</h1>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <QRCodeSVG value={`https://20.222.3.236/Auth?userToken=${userToken}&email=${email}`}/><br/>
          </div>
        </Loader>
      </div>
    </Container>
  );
}
export default AliceResultDemo;
