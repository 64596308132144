import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Loader({ src, children }){
  if(!src){
    return(
      <Spinner
        animation="border"
        variant="primary"
        style={{
          display: "block",
          margin: "auto",
        }}
      />
    );
  }
  const content = typeof children === "function" ? children(src) : children;
  return content;
}

export function AliceResult(){
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userToken = queryParams.get("userToken");
  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/Azure?userToken=${userToken}`);
  }
  return (
    <Container className="mt-5">
      <h4>本人確認完了</h4>
      <h4>診療明細を登録してください</h4>
      <Button variant="primary" type="submit" onClick={handleClick}>登録はこちら</Button>
    </Container>
  );
}

async function GetAuth(authID, email){
  console.log(authID);
  console.log(email);
  const response = await fetch(`https://ino-marke.japaneast.cloudapp.azure.com:8443/alice/authenticate?authID=${authID}&email=${email}`);
  const data = await response.json();
  return data;
}

export function AuthResult(){
  const navigate = useNavigate();
  const location = useLocation();
  const authID = location.state?.authID;
  const email = location.state?.email;
  console.log("authID: " + authID);
  console.log("email: " + email);
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/");
  }
  const [auth, setAuth] = useState();
  const [match, setMatch] = useState();
  useEffect(() => {
    const fetchAuth = async () => {
      try{
        const data = await GetAuth(authID, email);
        console.log(data);
        const match = data.authentication.face_matching;
        setAuth(data);
        setMatch(match);
      }catch(error){
        console.log(error.massage);
      }
    };
    fetchAuth();
  }, [authID, email]);
  return (
    <Container>
      <Button onClick={handleBackClick} size="lg">戻る</Button>
      <div></div>
      <Loader src={auth}>
        <h1>認証完了</h1>
        <h2>マッチング率：{match}</h2>
      </Loader>
    </Container>
  );
}

export function AzureResult(){
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userToken = queryParams.get("userToken");
  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/Azure?userToken=${userToken}`);
  }
  return (
    <Container className="mt-5">
      <h4>本人確認完了</h4>
      <h4>診療明細を登録してください</h4>
      <Button variant="primary" type="submit" onClick={handleClick}>登録はこちら</Button>
    </Container>
  ); 
}
