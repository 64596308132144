import React, { useState, useEffect } from 'react';
import './RetakeDemo.css';

async function GetPoint(image){
  const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/azure/getlaplacian', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
    },
    body: JSON.stringify({ image }) 
  });
  const data = await response.text();
  return parseFloat(data.trim(), 10);
}

async function GetEnhanced(image){
  const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/azure/edgeenhance', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
    },
    body: JSON.stringify({ image }) 
  });
  const data = await response.text();
  console.log(data);
  return data;
}

function GetCanvas(){
  return new Promise((resolve) => {
    const video = document.getElementById('video');
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    const aspectRatio = 4 / 3;
    let width, height, x, y;
    if(video.videoWidth / video.videoHeight > aspectRatio){
      width = video.videoHeight * aspectRatio;
      height = video.videoHeight;
      x = (video.videoWidth - width) / 2;
      y = 0;
    }else{
      width = video.videoWidth;
      height = width / aspectRatio;
      x = 0;
      y = (video.videoHeight - height) / 2;
    }
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(video, x, y, width, height, 0, 0, width, height);
    resolve(canvas.toDataURL());
//    resolve(ctx.getImageData(0, 0, width, height));
  })
}

function ConvertImage(image){
  return new Promise((resolve) => {
    let img = new Image();
    img.onload = function(){
      let canvas = document.getElementById('gray');
      let ctx = canvas.getContext('2d');
      canvas.width = 64;
      canvas.height = 64;
      ctx.drawImage(img, 0, 0, 64, 64);
  
      let imageData = ctx.getImageData(0, 0, 64, 64);
      var data = imageData.data;
      for (var i = 0; i < data.length; i += 4) {
        var gray = data[i] * 0.299 + data[i + 1] * 0.587 + data[i + 2] * 0.114;
        data[i] = gray; // 赤
        data[i + 1] = gray; // 緑
        data[i + 2] = gray; // 青
      }
      ctx.putImageData(imageData, 0, 0);
  
      var resizedImage = canvas.toDataURL('image/png');
      resolve(resizedImage);
    }
    img.src = image;
  })
}

async function DocCognition(image){
  const response = await fetch('https://ino-marke.japaneast.cloudapp.azure.com:8443/azure/doccognition', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
    },
    body: JSON.stringify({ image }) 
  });
  const data = await response.text();
  return data;
}

export function RetakeDemo(){
  const tf = require('@tensorflow/tfjs');
  const [devices, setDevices] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);
  const [sendPhoto, setSendPhoto] = useState(false);
  const [photoSrc, setPhotoSrc] = useState(null);
  const [images, setImages] = useState([]);
  const [points, setPoints] = useState([]);
  const [flag, setFlag] = useState(false);
  const [video, setVideo] = useState();
  const [canvas, setCanvas] = useState();
  const labels = ['OK', 'close', 'far', 'None'];
  const [label, setLabel] = useState();
  const numberOfShots = 5;
  let count;
  let interval;
  let model;
  const style = {
    maxWidth: '100%',
    aspectRatio: '4 / 3',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  };

  const changeNavi = (flag) => {
    const element = document.getElementById("navi");
    console.log(flag);
    if(flag){
      element.style.color = "green";
    }else{
      element.style.color = "red";
    }
  }

  const renderFrame = async () => {
    const start = Date.now();
    let image = await GetCanvas();
    let converted = new Image();
    let src = await ConvertImage(image);
    converted.onload = async function() {
      let tensor = tf.browser.fromPixels(converted, 1);
      tensor = tensor.toFloat().div(tf.scalar(255));
      tensor = tensor.expandDims();
      console.log(model);
      let prediction = model.predict(tensor);
      const result = labels[prediction.argMax(1).dataSync()[0]];
      const labelProbabilities = await prediction.data();
      const end = Date.now();
      const results = {};
      for (let i = 0; i < labels.length; i++) {
        results[labels[i]] = labelProbabilities[i];
      }
//      console.log(results);
      console.log(result + ":" + results[result]);
      if(result === "None"){
        setLabel("ドキュメントを枠に映してください");
        count = 3;
        changeNavi(false);
      }
      if(result === "close"){
        setLabel("遠ざけてください");
        count = 3;
        changeNavi(false);
      }
      if(result === "far"){
        setLabel("近づけてください");
        count = 3;
        changeNavi(false);
      }
      if(result === "OK"){
        setLabel("そのまま維持してください");
        count = count - 1;
        if(count <= 0){
          clearInterval(interval);
        }
        changeNavi(true);
      }
      
      console.log(count);
        
//      console.log("start:", start);
//      console.log("end  :", end);
    }
    converted.src = src;
  };
//    }
//  }, [canvas]);

  const startCamera = async () => {
    console.log("startCamera");
    model = await tf.loadLayersModel('./model.json');
    count = 3;
    setShowCamera(true);
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" }})
      .then(function(stream){
        const video = document.getElementById('video');
        setVideo(video);
        const canvas = document.getElementById('canvas');
        setCanvas(canvas);
        video.srcObject = stream;
        video.play();
      })
      .catch(err => {
        console.log(err.name + ": " + err.message);
      })
    interval = setInterval(() => {
      renderFrame();
    }, 1000);
  };

  const getPictures = async () => {
    console.log("getPictures");
    const tmpimages = [];
    const tmppoints = [];
    const promises = [];
    for (let i = 0; i < numberOfShots; i++) {
      promises.push(
        new Promise((resolve) => {
          setTimeout(async () => {
            const screenshot = await GetCanvas();
            console.log(`[${i}] Image captured: ${Date.now()}ms`); // 開始からの経過時間をログ出力
            tmpimages.push(screenshot);
//            const enhanced = await GetEnhanced(screenshot);
//            tmpimages.push(enhanced);
            setImages(tmpimages);
            const tmppoint = await GetPoint(screenshot);
            tmppoints.push(tmppoint);
            setPoints(tmppoints);
            resolve();
          }, i * 100);
        })
      );
    }
    await Promise.all(promises);
  };

  return(
    <div class="RetakeDemo">
      {!showCamera && !showPhoto && (
        <div>
          <button class="btn-start" onClick={startCamera}>カメラ起動</button>
        </div>
      )}
      {showCamera && (
        <div>
          <div class="webcam-container">
            <div style={style}>
              <div class="frame-overlay" id="navi"></div>
              <video id="video" width={1280} height={960} style={{objectFit:'cover', width:'100%', height:'100%'}} class="webcam-video" autoplay playsinline="playsinline"></video>
              <div id="navi" style={{position: 'absolute', top: '50%', left: '50%', color: 'red', fontSize: '20px', transform: 'translate(-50%, -50%)'}}>{label}</div>
            </div>
            <div style={style}>
              <div style={{ position: 'relative', width: '100%', height: '100%'}}>
                <canvas id="canvas" width={1280} height={960} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></canvas>
              </div>
            </div>
            <canvas id="gray"></canvas>
            <img id="img"></img>
          </div>
        </div>
      )}
    </div>

  );
}
